import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'reactstrap'
import { MdLocationOn } from 'react-icons/md'

import './index.scss'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import GlobeGraphic from '../../assets/graphics/graphic-globe.svg'
import VideoThumbnail from '../../assets/images/image-video-thumbnail.png'
import PartnerLogo from '../../assets/logos/logo-aapacn.svg'
import Squiggly from '../../assets/graphics/graphic-squiggly.svg'

class AboutPage extends Component {
	render() {
		return (
			<Layout>
				<SEO title='About' />
				<Container className='mb-5'>
					<Row className='about-page-moments-row'>
						<Col lg={6}>
							<Row className='about-page-main-title'>Moments move us.</Row>
							<Row>
								<img src={Squiggly} alt='Squiggly' />
							</Row>
							<Row className='about-page-main-text pt-0 mt-0'>
								<p>
									There has never been a more important time to share gratitude with nurses, doctors and other healthcare workers
									battling COVID-19. Carepostcard allows you to meaningfully honor those on the frontlines by sharing your stories
									and thanks.
									<br />
									<br />
									Carepostcard combines real-time gratitude from patients in hospitals and healthcare organizations with
									appreciation from the public to create a centralized hub to uplift, inspire, and further the Compassionate Care
									Movement. Whether it was a passing moment or a major event, share your voice with a Carepostcard.
								</p>
							</Row>
							<Row>
								<Button className='about-page-create-button ml-0 mt-4' href='/create'>
									Create a CarePostcard
								</Button>
							</Row>
						</Col>
						<Col lg={6} className='text-center'>
							<video controls poster={VideoThumbnail} className='about-video-preview'>
								<source src='https://carepostcard-env-development.s3.amazonaws.com/carepostcard-intro.mp4' type='video/mp4' />
							</video>
						</Col>
					</Row>
					<Row className='about-page-globe-row'>
						<Col lg={6} className='text-center'>
							<img src={GlobeGraphic} alt='' />
						</Col>
						<Col lg={6}>
							<Row className='about-page-main-title mt-5'>Live from Patients Nationwide</Row>
							<Row className='about-page-main-text pt-3 mt-0'>
								<p>
									The beautiful thing about Carepostcard is our real-time feed of patient gratitude coming from hospitals and health
									systems nationwide through the Wambi platform. Wambi enables real-time patient-driven and peer-to-peer recognition
									to impact patient experience, drive employee engagement, and reduce burnout and turnover. Here are some highlights
									of recent recognition coming from patients during their stay through Wambi.
								</p>
							</Row>
							<Row>
								<Button
									href='https://wambi.org/'
									target='_blank'
									rel='noopener noreferrer'
									className='about-page-learn-button ml-0 mt-4'
								>
									LEARN MORE
								</Button>
							</Row>
						</Col>
					</Row>
					<Row className='about-page-reviews-row'>
						<Col>
							<Row className='pb-2 pr-2 mr-2'>
								<Col xs={1}>
									<MdLocationOn className='about-page-map-icon ml-2' />
								</Col>
								<Col xs={10} className='text-left'>
									<Row className='about-page-title ml-2'>JFK Medical Center</Row>
									<Row className='about-page-subtitle ml-2'>Edison, NJ</Row>
								</Col>
							</Row>
							<Row className='about-page-text px-4 py-1'>
								<p>
									Maribeth came into the room and throughout the night whenever I needed something, she was right there. And as I
									watched her take care of the patient in the next bed all by herself, I immediately knew she is to be recognized as
									a shining star to this hospital.
								</p>
							</Row>
						</Col>
						<Col md={1}></Col>
						<Col>
							<Row className='pb-2'>
								<Col xs={1}>
									<MdLocationOn className='about-page-map-icon ml-2' />
								</Col>
								<Col xs={10} className='text-left'>
									<Row className='about-page-title ml-2'>Upper Chesapeake Hospital</Row>
									<Row className='about-page-subtitle ml-2'>Bel Air, MD</Row>
								</Col>
							</Row>
							<Row className='about-page-text px-4 py-1'>
								<p>
									Jessica was my first nurse once I was admitted. I appreciated her kindness and empathy. She looked past the
									disease and saw the patient, she saw me as a person and I was able to feel that. Thank you Jessica.
								</p>
							</Row>
						</Col>
					</Row>

					<Row className='about-page-banner p-5 my-5'>
						<Row className='about-page-banner-inner p-3 align-items-center'>
							<Col md={9}>
								<p className='mb-0'>
									Did you know more than 1.4 million Americans receive specialized nursing care in long-term and post-acute care
									facilities? AAPACN is honored to support these nurse heroes.
								</p>
							</Col>
							<Col md={3} className='pt-3 text-center'>
								<img src={PartnerLogo} alt='' />
							</Col>
						</Row>
					</Row>
					<Row className='about-page-main-title text-center mt-5 pb-2'>
						<Col>Let's do more together.</Col>
					</Row>
					<Row className='px-3 text-center'>
						<p className='about-page-do-more-text'>
							Interested in partnering with Carepostcard to do great things, or just have an idea you want to share? Let’s connect!
							<br />
							Drop us a note&nbsp;
							<a href='mailto: info@wambi.org'>here</a> and we’ll be in touch. And of course, let’s stay connected wherever you prefer
							to be social.
						</p>
					</Row>
				</Container>
			</Layout>
		)
	}
}

export default AboutPage
